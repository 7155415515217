var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Grid } from "@mui/material";
import "survey-core/defaultV2.min.css";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import "survey-creator-core/survey-creator-core.min.css";
import { setLicenseKey } from "survey-core";
import { useCallback } from "react";
import { createNPS } from "./apis/nps";
setLicenseKey("NTIwMDBjNWMtODk5My00YzE1LWExNWItYmEyY2E0OTFjZjdjOzE9MjAyNS0xMi0xMiwyPTIwMjUtMTItMTIsND0yMDI1LTEyLTEy");
export var App = function () {
    var creatorOptions = {
        showLogicTab: true,
        isAutoSave: true,
    };
    var creator = new SurveyCreator(creatorOptions);
    var buttonNewNPS = useCallback(function () {
        var NovoNPS = {
            titulo: "Pesquisa de Satisfação",
            descricao: "Novo NPS",
            json_data: creator.text,
        };
        createNPS(NovoNPS)
            .then(function (response) {
            console.log(response);
        })
            .catch(function (err) {
            console.log(err);
        });
    }, [creator.text]);
    return (_jsxs(Grid, __assign({ container: true }, { children: [_jsx("h1", { children: "NPS Designer" }), _jsx(SurveyCreatorComponent, { creator: creator }), _jsx(Button, __assign({ onClick: function () { return buttonNewNPS(); } }, { children: "Salvar Formul\u00E1rio" }))] })));
};
